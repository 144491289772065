import {Consent, ConsentData} from "../3rdparty/consent/Consent";
import Landmark from '@core/scripts/components/landmark-adapter';
import fetchWithCSRF from '@core/scripts/helper/fetch-with-csrf';
import Url from "@core/scripts/helper/url";
import CookieHelper from '@core/scripts/helper/cookie';
import JshModule from "@core/scripts/helper/jsh-module";

const COOKIE_PATH = '/';
const COOKIE_DOMAIN = '.' + location.hostname.split('.').slice(-2).join('.') + ';';
const COOKIE_SECURE = window.location.protocol === 'https:';


const {moduleScope, config} = JshModule('hurra');

function updatePlaceholderSize() {
    let consentBar = document.getElementById("JOISH987_bar")

    if (consentBar) {
        document.body.style.marginBottom = `${consentBar.offsetHeight}px`;
    }
}

function setPlaceholderSize() {
    updatePlaceholderSize();
    window.addEventListener("resize", function (e) {
        updatePlaceholderSize();
    });
}

function hidePlaceholder() {
    document.body.style.marginBottom = "0";
}

let intervalConsentBannerCheck = setInterval(() => {
    var gdprATTCookie = CookieHelper.get("gdpr_att");
    var hasClassATTBlocked = document.getElementById('#JOISH987_bar_holder')?.hasClass('JOISH987_att_blocked')

    if (Consent.isConsentBannerVisible() || Consent.isConsentModalVisible()) {
        if (Consent.isConsentBannerVisible()) {
            setPlaceholderSize();
            let consentElementVisibleLandmarkValues = {
                landmark: "optin",
                banner: "view",
                source: "hurra",
                device: config.device
            };
            Landmark.sendLandmark(consentElementVisibleLandmarkValues)
        }
        document.getElementById('JOISH987_showModal_btn')?.addEventListener('click', () => {
            let openConsentSettingsLandmarkValues = {
                landmark: "optin",
                source: "hurra",
                banner: "settings"
            };
            Landmark.sendLandmark(openConsentSettingsLandmarkValues);
        });
        document.getElementById('JOISH987_closeModalTop_btn')?.addEventListener('click', () => {
            let closeConsentSettingsLandmarkValues = {
                landmark: "optin",
                source: "hurra",
                banner: "closeSettings"
            };
            Landmark.sendLandmark(closeConsentSettingsLandmarkValues);
        });
        document.getElementById('JOISH987_acceptAllBar_btn')?.addEventListener('click', () => {
            let categoryStates = {
                PERFORMANCE: 1,
                ESSENTIAL: 1,
                ADVERTISEMENT: 1,
                PERSONALIZATION: 1
            }
            updateConsent(categoryStates);
            hidePlaceholder();

            optInMarketingVendors();
        });
        document.getElementById('JOISH987_inlineRejectBar_btn')?.addEventListener('click', () => {
            window.localStorage.removeItem("CONSENT_COMMUNICATED_STATUS")
            let categoryStates = {
                PERFORMANCE: 0,
                ESSENTIAL: 1,
                ADVERTISEMENT: 0,
                PERSONALIZATION: 0
            }
            updateConsent(categoryStates);
            hidePlaceholder();
            optOutMarketingVendors();
        });
        document.getElementById('JOISH987_acceptModal_btn')?.addEventListener('click', () => {
            if (gdprATTCookie === "0" || hasClassATTBlocked) return;

            let personal = getConsent(document.getElementById("JOISH987_personalization_c"));
            let analytics = getConsent(document.getElementById("JOISH987_analytics_c"));
            let marketing = getConsent(document.getElementById("JOISH987_advertisement_c"));
            let categoryStates = {
                PERFORMANCE: analytics,
                ESSENTIAL: 1,
                ADVERTISEMENT: marketing,
                PERSONALIZATION: personal
            }
            updateConsent(categoryStates);
            hidePlaceholder();
            if (marketing !== "1") {
                optOutMarketingVendors();
            } else {
                optInMarketingVendors();
            }
        });

        document.getElementById('JOISH987_acceptAllModal_btn')?.addEventListener('click', () => {
            if (gdprATTCookie === "0" || hasClassATTBlocked) return;

            let categoryStates = {
                PERFORMANCE: 1,
                ESSENTIAL: 1,
                ADVERTISEMENT: 1,
                PERSONALIZATION: 1
            }
            updateConsent(categoryStates);
            hidePlaceholder();
            optInMarketingVendors();
        });

        document.getElementById('JOISH987_rejectModal_btn')?.addEventListener('click', () => {
            if (gdprATTCookie === "0" || hasClassATTBlocked) return;

            window.localStorage.removeItem("CONSENT_COMMUNICATED_STATUS")
            let categoryStates = {
                PERFORMANCE: 0,
                ESSENTIAL: 1,
                ADVERTISEMENT: 0,
                PERSONALIZATION: 0
            }
            updateConsent(categoryStates);
            hidePlaceholder();
            optOutMarketingVendors();
        });
        clearInterval(intervalConsentBannerCheck)
    }
}, 300)


function optOutMarketingVendors() {
    // OptOut Nitro
    CookieHelper.remove("FP_NITRO", {path: COOKIE_PATH, domain: COOKIE_DOMAIN, secure: COOKIE_SECURE});
}

function optInMarketingVendors() {
    console.log("Init nitro again");
    window.nitro?.init();
}

function updateConsent(categoryStates) {
    fetchWithCSRF(Url.toAbsolute('/ajax/consent/update/'), {
        method: "POST",
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({vendorStates: {}, categoryStates: categoryStates})
    })
}

function getConsent(consentElementType) {
    return (consentElementType && consentElementType.classList.contains("JOISH987_box__label__option--active")) ? "1" : "0";
}
